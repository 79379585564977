<template>
  <div>
    <div
      id="menu-bar"
      :class="[isOpen ? 'open' : '', wide ? 'wide' : '', device !== 'desktop' ? 'full-wide' : '']"
      ref="sidebar"
    >
      <CollapseNavItem
        :name="$t('Menu.Groups.CompanySettings')"
        :variant="variant"
        :items="settingsItems"
        :selected="currentPage.includes('/company-settings')"
        :icon="icons.SettingsIcon"
        :closeDropdown="closeDropdown"
        data-redirect=""
        v-show="isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Settings'))"
      />

      <CollapseNavItem
        :name="$t('Menu.Groups.Manufacturing')"
        :variant="variant"
        :items="manufacturingItems"
        :selected="currentPage.includes('/manufacturing')"
        :icon="icons.ManufacturingIcon"
        :closeDropdown="closeDropdown"
        data-redirect=""
        v-show="isOpen ? true : !overflowNavItems.some((item) => item.name == $t('Manufacturing'))"
      />

      <Extra
        :items="overflowNavItems"
        :variant="variant"
        v-show="overflowNavItems.length"
      />
    </div>
  </div>
</template>

<script>
  // Icons
  import ExtraIcon from '@core/assets/icons/extra.svg';
  import SettingsIcon from '@core/assets/icons/settings-icon.svg';
  import ManufacturingIcon from '@core/assets/icons/manufacturing-icon.svg';

  // Components
  import CollapseNavItem from '../CollapseNavItem.vue';
  import Extra from '../Extra/index.vue';

  const ICONS = {
    ExtraIcon,
    SettingsIcon,
    ManufacturingIcon
  };

  export default {
    name: 'MenuBar',
    components: {
      // Icons
      ExtraIcon,
      SettingsIcon,
      ManufacturingIcon,

      // Components
      CollapseNavItem,
      Extra
    },
    props: {
      wide: {
        type: Boolean,
        default: false
      },
      user: {
        type: Object
      },
      device: {
        type: String,
        default: 'desktop'
      },
      closeDropdown: {
        type: Boolean
      }
    },
    data() {
      return {
        isOpen: this?.wide ? true : false,
        icons: ICONS,
        prefix: this.$router.currentRoute.params.prefix,
        visibleNavItems: [],
        overflowNavItems: [],
        settingsItems: [
          {
            title: 'Menu.Items.OrganizationalStructure',
            click: () => this.redirect('organizational-structure'),
            slug: 'organizational-structure'
          },
          {
            title: 'WorkcenterList.Resources',
            click: () => this.redirect('resources'),
            slug: 'resources'
          },
          {
            title: 'Breadcrumbs.Departments',
            click: () => this.redirect('departments'),
            slug: 'departments'
          },
          {
            title: 'Menu.Items.Security',
            slug: 'security',
            items: [
              {
                title: 'Menu.Items.Profiles',
                click: () => this.redirect('profiles'),
                slug: 'profiles'
              },
              {
                title: 'Menu.Items.People',
                click: () => this.redirect('people'),
                slug: 'people'
              }
            ]
          },
          {
            title: 'Breadcrumbs.DataFields',
            click: () => this.redirect('data-fields'),
            slug: 'data-fields'
          },
        ],
        manufacturingItems: [
          {
            title: 'Menu.Items.Materials',
            click: () => this.redirect('materials'),
            slug: 'materials'
          }
        ]
      };
    },
    computed: {
      variant() {
        return this.isOpen ? 'full' : 'short';
      },
      currentPage() {
        return this.$route.path;
      },
      language() {
        return this.$i18n.locale;
      }
    },
    mounted() {
      this.addVisibleNavItems();
    },
    created() {
      window.addEventListener('resize', this.addVisibleNavItems);
    },
    destroyed() {
      window.removeEventListener('resize', this.addVisibleNavItems);
    },
    methods: {
      redirect(page) {
        if (this.$route.name === page) return;
        if (page == 'myind') {
          window.location.href = this.$myIndLink;
          return;
        }

        if (page === 'support') {
          window.location.href = `${this.$myIndLink}${localStorage.getItem('prefix')}/support`;
          return;
        }

        const userInfo = this.$cookies.get('userInfo');

        let tenant = userInfo.userData.email.split('@');
        this.prefix = this.prefix ? this.prefix : tenant[1].split('.')[0];

        this.$router.push({ name: page, params: { prefix: this.prefix } });

        if (this.wide) {
          this.$emit('close');
        }
      },
      addVisibleNavItems() {
        const sidebar = this.$refs.sidebar;
        const navItemGroups = [
          this.$refs.generalGroup,
          this.$refs.funcionalitiesGroup,
          this.$refs.helpGroup
        ];

        // Tamanho da viewport
        const viewportHeight = window.innerHeight - 100;
        // Posição do topo do sidebar em relação ao documento
        const sidebarTop = sidebar.getBoundingClientRect().top;
        // Itens visíveis e itens que ultrapassam o limite da viewport
        const visibleNavItems = [];
        const overflowNavItems = [];

        // Possivelmente será utilizado no futuro

        // for (const group of navItemGroups) {
        //   const groupTitle = group.title;
        //   const navItems = group.$children;
        //   for (const item of navItems) {
        //     const itemRect = item.$el.getBoundingClientRect();
        //     const itemTop = itemRect.top - sidebarTop;
        //     if (itemTop >= 0 && itemTop + itemRect.height + 30 <= viewportHeight) {
        //       // O item está visível na viewport
        //       visibleNavItems.push({
        //         group: groupTitle,
        //         navItem: item
        //       });
        //     } else {
        //       // O item ultrapassa o limite da viewport
        //       overflowNavItems.push({
        //         group: groupTitle,
        //         name: item.name,
        //         icon: item.icon,
        //         click: () => this.redirect(item.$el.dataset.redirect)
        //       });
        //     }
        //   }
        // }

        // Atualizar a lista de itens visíveis e itens que ultrapassam a viewport
        this.visibleNavItems = visibleNavItems;
        this.overflowNavItems = overflowNavItems;
      }
    },
    watch: {
      language() {
        this.$nextTick(() => {
          this.addVisibleNavItems();
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .side-items {
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .close-icon {
    width: 12px;
    height: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-full-close-btn {
    outline: 0;
    border: 1px solid transparent;
    background: transparent;
    cursor: pointer;

    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -khtml-border-radius: 50%;
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;

    border-radius: 500px;
    border: 1px solid #cfc4be;
    background: #fff;

    svg {
      fill: #4c4541;
    }

    &:hover {
      border: 1px solid #974900;
    }

    &:active {
      border: 1px solid #e1730b;
    }
  }
  .toggle-icon {
    width: 10px;
    height: 10px;
  }

  #menu-bar {
    position: fixed !important;
    width: 80px;
    height: 100vh;
    padding: 16px 0;

    transition: all 0.6s;

    background-color: #fff;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);

    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.open {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      padding-bottom: 60px;
    }
    .settings-list {
      &.collapse-sidebar-open {
        background-color: #fafafa !important;
      }
    }
  }

  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-full {
    padding: 23px 16px;
    text-align: left;
  }

  .user-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
  }

  .toggle-sidebar {
    width: 24px;
    height: 24px;

    padding: 8px;

    justify-content: center;
    align-items: center;
    gap: 10px;
    outline: 0;

    position: absolute;
    right: -12px;
    top: 24px;

    border-radius: 50%;
    border: 1px solid #cfc4be;
    background-color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: #4c4541;
      display: flex;
    }

    &:hover {
      border: 1px solid #974900;
      background-color: #ffede2;
      svg {
        path {
          fill: #4c4541;
        }
      }
    }

    &:active {
      background-color: #ffdbc4;
    }

    span {
      color: white;
    }

    cursor: pointer;
  }

  .open {
    width: 260px !important;
    gap: 12px !important;
  }

  .wide {
    position: fixed !important;
    top: 49px !important;
    left: 0 !important;
    z-index: 5 !important;
    width: 260px !important;
    overflow-y: auto !important;
    background-color: #fff !important;
  }
  .full-wide {
    width: 100vw !important;
  }
</style>
