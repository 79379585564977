<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ $t('Footer.Copyright') }} {{ new Date().getFullYear() }}
      <span class="indsiders">{{ $t('Footer.Indsiders') }}</span
      >.
      <span v-show="showAllRightsReserved">{{ $t('Footer.AllRightsReserved') }}</span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink
  },
  data: () => ({
    showAllRightsReserved: window.innerWidth > 540
  }),
  beforeMount() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.showAllRightsReserved = window.innerWidth > 540;
    }
  }
};
</script>

<style>
.footer:has(.clearfix.mb-0) {
  padding: 0 !important;
}
</style>

<style lang="scss" scoped>
.clearfix.mb-0 {
  padding: 24px 29px;
  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: #4c4541;
    margin: 0 !important;
  }
  .indsiders {
    color: #ff8d2e;
  }
}
@media (max-width: 480px) {
  .clearfix.mb-0 {
    padding: 24px 16px;
  }
}
</style>
